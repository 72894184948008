<template>

  <div class="wrap">
    <header class="header">
      <img class="header-title" src="/images/interview/title.png" alt="" />
    </header>
    <div class="bottom-box" v-if="data.data.content">
      <NewsHeader :data-source="data.data"/>
      <div class="player-box" @click="playClick" v-show="$fileType.isVideo(data.data.video)">
        <video ref="videoPlayer" class="video" :src="data.data.video"
        ></video>
        <div class="player-cover-box" v-show="!isPlay">
          <img src="/images/common/play.png" class="playImg">
        </div>
      </div>
      <AudoPlayer :data="data.data" v-show="!$fileType.isVideo(data.data.video)"/>
      <div class="main-content bottom-box" v-html="data.data.content"></div>
    </div>
    <div class="bottom-box" v-else>
        <p>小镇访谈录</p>
      <div class="bottom-nodata-box">
        <img :style="{width: 1.13 + 'rem',eight: 1.13 + 'rem'}" src="/images/home/empty-common.png" alt="">
        <p>建设中</p>
      </div>

    </div>

  </div>
</template>

<script>
import NewsHeader from '@/components/common/newsheader'
import { reactive, ref} from 'vue'
import api from '@/api'
import {useRoute} from 'vue-router'
import NoData from '@/components/common/nodata'

export default {
  name: 'interview-detail',
  components:{
    NewsHeader,
    // NoData,

  },
   setup () {
    const route = useRoute()
    const html = ref('');
    const data = reactive({data:{}})
     const videoPlayer = ref()
     const isPlay = ref(false)

    const read = async (id) => {
      await api.readStoryDetail({id: id});
    }
    const getList = async () => {
      let res = await api.townStory({pageNum: 1, pageSize: 1, tabs: 'TALK', townId: route.query.tid})

      if (res.records && res.records.length >= 0) {
        let firstObj = res.records[0];

          getData(firstObj.id);
      }

    }


    const getData = async (id) => {
      let res = await api.storyDetail({id: id});

      data.data = res;
      read(res.id);
    }


    if (route.query?.tn) { //如果存在小镇名称则为单个小镇 ;需要先获取列表，在获取详情
      getList();
    } else {
      getData(route.query.id)

    }
     const playClick = () => {
       if (isPlay.value) {
         isPlay.value = !isPlay.value
         videoPlayer.value.pause()
       } else {
         isPlay.value = !isPlay.value
         videoPlayer.value.play()
       }
     }

    return {
      html,
      data,
      playClick,
      videoPlayer,
      isPlay
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  min-height: calc(100vh - 1.06rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: red;
  .header {
    height: 7.36rem;
    width: 100%;
    background: url("/images/interview/banner.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;

    .header-title {
      margin-top: 2.88rem;
      width: 6.4rem;
      height: 1.6rem;
      object-fit: cover;
    }
  }
  .bottom-box {
    width: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.6rem;
    margin-bottom: 0.3rem;

    p {
      font-weight: 500;
      font-size: 0.3rem;
    }
    .bottom-nodata-box {
      display: flex;
      margin: 2rem 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        color: #B8B8B8;
        font-size: 0.18rem;
        font-weight: 400;
      }
    }
  }
  .player-box {
    background: black;
    height: 4.36rem;
    width: 12rem;
    position: relative;

    .video {
      height: 4.36rem;
      width: 12rem;
    }

    .player-cover-box {
      display: flex;
      position: absolute;
      height: 4.36rem;
      width: 12rem;

      top: 0;
      justify-content: center;
      align-items: center;

      .playImg {
        width: 0.97rem;
        height: 0.97rem;
      }

    }
  }
}
</style>
